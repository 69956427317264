import { DcChatbotStatus, OrganizationUserRole } from "docuchatcommontypes";
import type { ActionPermissions, UserAction } from "~/types/navigation";
import type { LinkWithPermission } from "~~/types/navigation";

export function useNavigation() {
  const t = useNuxtApp().$i18n.t;
  const { user, plan: userPlan, isAuthenticated } = storeToRefs(useUserStore());

  const mainLinks: LinkWithPermission[] = [
    {
      label: t("base.navigation.chatbots.label"),
      icon: "i-carbon-bot",
      to: "/user/chatbots",
      exact: true,
      accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
      children: [
        {
          label: t("base.navigation.chatbots.sub.create"),
          icon: "i-carbon-add",
          to: "/user/chatbots/create",
          accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
        },
        {
          label: t("base.navigation.chatbots.sub.edit"),
          icon: "i-carbon-edit",
          to: "/user/chatbots/edit/",
          accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
        },
        {
          label: t("base.navigation.chatbots.sub.chat"),
          icon: "i-carbon-chat",
          to: "/user/chatbots/chat/",
          accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
        },
      ],
    },
    {
      label: t("base.navigation.documents.label"),
      icon: "i-carbon-document-multiple-01",
      to: "/user/documents",
      accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
    },
    {
      label: t("base.navigation.analytics.label"),
      icon: "i-carbon-chart-ring",
      to: "/user/analytics",
      accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
      children: [
        {
          exact: true,
          label: t("base.navigation.analytics.sub.insights"),
          to: "/user/analytics",
          accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
        },
        {
          label: t("base.navigation.analytics.sub.sessions"),
          to: "/user/analytics/sessions",
          accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
        },
      ],
    },
    {
      label: t("base.navigation.team.label"),
      icon: "i-carbon-user-multiple",
      to: "/user/team",
      accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
    },
  ];

  const footerLinks: LinkWithPermission[] = [
    {
      label: t("base.navigation.help.label"),
      icon: "i-carbon-help",
      to: "/user/support",
      accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
    },
    {
      label: t("base.navigation.settings.label"),
      icon: "i-carbon-settings",
      to: "/user/settings",
      accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
      children: [
        {
          exact: true,
          label: t("base.navigation.settings.sub.account"),
          to: "/user/settings",
          accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
        },
        {
          label: t("base.navigation.settings.sub.organization"),
          to: "/user/settings/organization",
          accessibleTo: [OrganizationUserRole.SuperAdmin],
        },
        {
          label: t("base.navigation.settings.sub.integrations"),
          to: "/user/settings/integrations",
          accessibleTo: [OrganizationUserRole.SuperAdmin],
          accessibleWhen: () => userPlan.value?.requiresAzureOpenAiIntegration ?? false,
        },
        {
          label: t("base.navigation.settings.sub.plan"),
          to: "/user/settings/plan",
          accessibleTo: [OrganizationUserRole.SuperAdmin],
        },
        {
          label: t("base.navigation.settings.sub.legal"),
          to: "/user/settings/legal",
          accessibleTo: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
        },
      ],
    },
  ];

  const actionPermissions: ActionPermissions = {
    CreateChatbot: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
    ShareChatbot: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
    ChatWithChatbot: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
    EditChatbot: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
    DeleteChatbot: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
    AddDocument: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
    OpenDocument: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin, OrganizationUserRole.Member],
    DeleteDocument: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
    RenameDocument: [OrganizationUserRole.SuperAdmin, OrganizationUserRole.Admin],
    DeleteAccount: [OrganizationUserRole.SuperAdmin],
    OpenBillingPortal: [OrganizationUserRole.SuperAdmin],
  };

  const allowedLinks = computed(() => {
    const allowedMainLinks = mainLinks.filter(f => isAllowedToGo(f));
    const allowedFooterLinks = footerLinks.filter(f => isAllowedToGo(f));
    return {
      main: allowedMainLinks,
      footer: allowedFooterLinks,
    };
  });

  /**
   * Returns child link if the current route is a child link, otherwise returns the main or footer link.
   */
  const currentLink = computed(() => {
    const path = useRoute().path;
    const allLinks = [...mainLinks, ...footerLinks];

    // First, try to find a matching child link across all main and footer links
    for (const link of allLinks) {
      const matchingChild = link.children?.find(child => path.includes(child.to as string));

      // If matching child link is the same as the parent link, we don't want to return it.
      // Otherwise, the title of that parent page will be the child link title.
      if (matchingChild && matchingChild.to !== link.to)
        return matchingChild;
    }

    // If no matching child link is found, find and return the main or footer link
    return allLinks.find(f => path.includes(f.to as string));
  });

  const currentLinkTabs = computed<LinkWithPermission[]>(() => {
    const currentLink = [...mainLinks, ...footerLinks].find(f => useRoute().path.includes(f.to as string));
    return currentLink?.children?.filter(f => isAllowedToGo(f)) ?? [];
  });

  function isAllowedToGo(link: LinkWithPermission) {
    const rolePermission = link.accessibleTo.includes(user.value?.organizationUserRole ?? OrganizationUserRole.Member);
    const statePermission = link.accessibleWhen ? link.accessibleWhen() : true;
    return rolePermission && statePermission;
  }

  function isAllowedToDo(action: UserAction) {
    const userRole = user.value?.organizationUserRole ?? OrganizationUserRole.Member;
    return actionPermissions[action].includes(userRole);
  }

  async function goToMainPage() {
    await navigateTo(isAuthenticated.value ? mainLinks[0].to : "/signin");
  }

  async function goToCreatePage() {
    if (!useLimits().canCreateChatbot.value) {
      useToast().add({
        title: t("user.chatbots.toasts.limitReached.title"),
        description: t("user.chatbots.toasts.limitReached.description"),
        icon: "i-carbon-bot",
        color: "amber",
      });
      return;
    }

    await navigateTo("/user/chatbots/create");
  }

  async function goToEditPage(chatbotId: string) {
    await navigateTo(`/user/chatbots/edit/${chatbotId}`);
  }

  async function goToChatPage(chatbotStatus: DcChatbotStatus, chatbotId: string) {
    // We ignore the ready status for notifications because we don't want to show a notification then
    const canChat = useChatbot().validateCanChat(chatbotId, chatbotStatus, [DcChatbotStatus.Ready]);
    if (!canChat) {
      await goToEditPage(chatbotId);
      return;
    }

    const ownedByUser = useChatbotStore().userChatbots.some(f => f.id === chatbotId);
    const inUserOrganization = useChatbotStore().organizationChatbots.some(f => f.id === chatbotId);
    const chatRoute = (ownedByUser || inUserOrganization)
      ? `/user/chatbots/chat/${chatbotId}`
      : `/chat/${chatbotId}`;

    await navigateTo(chatRoute);
  }

  function isOnPostcheckoutPage() {
    return useRoute().path.includes("/postcheckout");
  }

  return {
    allowedLinks,
    currentLink,
    currentLinkTabs,
    isAllowedToGo,
    isAllowedToDo,
    goToMainPage,
    goToCreatePage,
    goToEditPage,
    goToChatPage,
    isOnPostcheckoutPage,
  };
}
